import React, {useState, useEffect} from "react";
import dayjs from "dayjs";
import API from "../../../../shared/API";
import {Organisation_Receiver} from "../../../../context/organisation";

const SentMessages = ({organisation}) => {
    const [sentMessages, setSentMessages] = useState([]);

    useEffect(() => {
        Promise.all([
            API.org.sms.getSentMessages(organisation.data._id)
        ]).then((res) => {
            setSentMessages(res[0]);
        }).catch((error) => {
            console.log(error);
        });
    }, [organisation]);

    return (
        <div className="w-full">
            <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
                Sent Messages (Recent 20)
            </div>
            <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border border-gray-700">
                <thead>
                    <tr className="rounded-md bg-gray-900">
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">App</th>
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sent From</th>
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Recipients</th>
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Credits Used</th>
                    </tr>
                </thead>
                <tbody className="bg-black divide-y divide-gray-700">{sentMessages.length === 0 && (
                    <tr>
                        <td colSpan={7} className={"p-4"}>There are no recently sent SMS messages to display.</td>
                    </tr>
                )}
                {sentMessages.map((message) => {
                    return (
                        <tr key={message._id} className="items-center divide-x-2 divide-gray-700  ">
                            <td className="px-2 py-2 text-md text-gray-200">{dayjs(message.createdAt).format("DD/MM/YYYY hh:mma")}</td>
                            {/*<td className="px-2 py-2 text-md text-gray-200">{message.message.substr(0, 50)}{message.message.length > 50 && "..."}</td>*/}
                            <td className="px-2 py-2 text-md text-gray-200">{message.message}</td>
                            <td className="px-2 py-2 text-md text-gray-200">{message.application !== null ? message.application.name : "[UNKNOWN]"}</td>
                            <td className="px-2 py-2 text-md text-gray-200">
                                {message.gatewayPhoneNumber !== undefined ? message.gatewayPhoneNumber?.phoneNumber : (
                                  (message.custom_phone_number !== undefined && message.custom_phone_number !== null) ? message.custom_phone_number : message.from_phone_number?.number
                                )}
                            </td>
                            <td className="px-2 py-2 text-md text-gray-200">{message.to_phone_numbers !== undefined ? message.to_phone_numbers : message._totalRecipients}</td>
                            <td className="px-2 py-2 text-md text-gray-200">{message.status}</td>
                            <td className="px-2 py-2 text-md text-gray-200">{message.credits_used !== undefined ? message.credits_used : message.creditCost}</td>
                        </tr>
                    );
                })}</tbody>
            </table>
        </div>
    );
}
export default Organisation_Receiver(SentMessages, (organisation) => {
  return {organisation};
});