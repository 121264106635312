import React, {useState} from "react";
import {AuthComponents} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import {Switch} from "@headlessui/react";
import TextLocal_Logo from "../../../../assets/images/textlocal-icon.png"
import Twilio_Logo from "../../../../assets/images/twilio-icon.png"
import BT_Logo from "../../../../assets/images/bt-icon.png"
import QuickSMS_Logo from "../../../../assets/images/quicksms-icon.png"
import {Organisation_Receiver} from "../../../../context/organisation";
import PhoneNumberAssignment from "./phoneNumberAssignment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const InboxPhoneNumbers = ({phoneNumbers, organisation, isV2, onDataReload, onChangeSenderClick}) => {
  const [showPhoneNumberAssignment, setShowPhoneNumberAssignment] = useState(false)

  return (
    <div className="w-full">
      {showPhoneNumberAssignment && <PhoneNumberAssignment isV2={isV2} phoneNumbers={phoneNumbers} onDataReload={onDataReload} onClose={() => {
        setShowPhoneNumberAssignment(false);
      }} />}

      <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl">
        Assigned Phone Numbers
        {isV2 && <AuthComponents.Can scope={"sarstuff:global:admins"}>
          <button className={"float-right border rounded p-1 text-xs"} onClick={() => setShowPhoneNumberAssignment(true)}>Assignments</button>
        </AuthComponents.Can>}
      </div>
      <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border border-gray-700">
        <thead>
          <tr className="rounded-md bg-gray-900">
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provider</th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sending As</th>
        </tr>
        </thead>
        <tbody className="bg-black divide-y divide-gray-700">
        {(phoneNumbers === undefined || phoneNumbers.length === 0) && (
          <tr>
            <td colSpan={4} className={"p-4"}>There are no phone numbers assigned to your account, please contact
              SarStuff Support.
            </td>
          </tr>
        )}
        {phoneNumbers !== undefined &&
          phoneNumbers.map((phoneNumber) => {
            if (phoneNumber._isSender !== undefined) {
              const isSender = phoneNumber._isSender;
              return (
                <tr key={phoneNumber._id} className="items-center divide-x-2 divide-gray-700">
                  <td className="px-2 py-2 text-md text-gray-200">
                    {phoneNumber.phoneNumber}
                    <span className={"float-right"}>
                      {phoneNumber.gateway.provider === "TextLocal" && (<img className={"h-5"} src={TextLocal_Logo} alt={"TextLocal Logo"}/>)}
                      {phoneNumber.gateway.provider === "Twilio" && (<img className={"h-5"} src={Twilio_Logo} alt={"Twilio Logo"}/>)}
                      {phoneNumber.gateway.provider === "BT" && (<img className={"h-5"} src={BT_Logo} alt={"BT Messaging Logo"}/>)}
                      {phoneNumber.gateway.provider === "QuickSMS" && (<img className={"h-5"} src={QuickSMS_Logo} alt={"QuickSMS Logo"}/>)}
                    </span>
                    <span className={"block text-blue-500 font-italic"}>{phoneNumber.title}</span>
                  </td>
                  <td className="px-2 py-2 text-md text-gray-200">
                    {phoneNumber.isShared ? "Shared" : "Dedicated"}
                  </td>
                  <td className="px-2 py-2 text-md text-gray-200">
                    {phoneNumber.gateway.isSarStuff ? "SarStuff" : "Yourself"}
                  </td>
                  <td className="text-md text-gray-200 text-center">
                    <Switch
                      checked={isSender}
                      onChange={() => onChangeSenderClick(phoneNumber._id)}
                      className={classNames((isSender ? "bg-green-600" : "bg-gray-200"), "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-auto")}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames((isSender ? "translate-x-5" : "translate-x-0"), "inline-block h-5 w-5 rounded-full bg-black shadow transform ring-0 transition ease-in-out duration-200")}
                      />
                    </Switch>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={phoneNumber._id} className="items-center divide-x-2 divide-gray-700">
                <td className="px-2 py-2 text-md text-gray-200">
                  {phoneNumber.number}
                  <span className={"block text-blue-500 font-italic"}>{phoneNumber.name}</span>
                </td>
                <td className="px-2 py-2 text-md text-gray-200">
                  {phoneNumber.type}
                </td>
                <td className="px-2 py-2 text-md text-gray-200">
                  SarStuff
                  {/*{phoneNumber.expires !== undefined && phoneNumber.expires !== null ? dayjs(phoneNumber.expires).format("DD/MM/YYYY") : "Lifetime"}*/}
                </td>
                <td className="px-2 py-2 text-md text-gray-200">
                  {organisation.data?.sms?.defaultPhoneNumber === phoneNumber._id && <span className={"ml-2 px-3 py-1 rounded bg-green-800 text-white text-sm float-right"}>Yes</span>}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Organisation_Receiver(InboxPhoneNumbers, (organisation) => {
  return {organisation};
});