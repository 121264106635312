import React, { useEffect, useState } from "react";
import PhoneNumber_FormPopup from "./PhoneNumber_FormPopup";
import API from "../../../../../../shared/API";
import AlertMessage from "../../../../../../shared/components/AlertMessage";

const Gateway_PhoneNumberList = ({ gateway }) => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState({});
  const [serverAddress, setServerAddress] = useState("[server_url]")

  useEffect(() => {
    handleGetData();
  }, [gateway._id]);

  const handleGetData = () => {
    API.management.sms.gateways.phoneNumbers.getAll(gateway._id).then(res => {
      setPhoneNumbers(res);
    })
    API.management.sms._serverUrl().then(res => {
      setServerAddress(res);
    })
  }

  return (
    <div>
      <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
        Phone Numbers
        <button onClick={() => setShowCreate(true)} className={`bg-green-600 p-2 rounded-md`}>Create</button>
      </div>

      {showCreate && <PhoneNumber_FormPopup gateway={gateway} onClose={() => {
        setShowCreate(false);
        handleGetData();
      }} phoneNumber={{}} />}
      {showEdit._id !== undefined && <PhoneNumber_FormPopup isEdit={true} gateway={gateway} onClose={() => {
        setShowEdit({});
        handleGetData();
      }} phoneNumber={showEdit} /> }

      <div className="rounded-md border border-gray-700">
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700">
          <thead>
            <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Phone Number
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Shared/Dedicated
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Can Receive Texts
            </th>
          </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{phoneNumbers.map(phoneNumber => {
            return (
              <React.Fragment key={phoneNumber._id}>
                <tr
                  onClick={() => setShowEdit(phoneNumber)}
                  className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
                >
                  <td className="px-2 py-2 text-md text-gray-200 text-center font-bold" colSpan={3}>{phoneNumber.title}</td>
                </tr>
                <tr
                  onClick={() => setShowEdit(phoneNumber)}
                  className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
                >
                  <td className="px-2 py-2 text-md text-gray-200">{phoneNumber.phoneNumber}</td>
                  <td className="px-2 py-2 text-sm text-gray-200">{phoneNumber.isShared ? <span className={"py-1 px-2 rounded bg-yellow-600 text-white"}>Shared</span> : <span className={"py-1 px-2 rounded bg-purple-600 text-white"}>Dedicated</span>}</td>
                  <td className="px-2 py-2 text-sm text-gray-200">{phoneNumber.isInbound ? <span className={"py-1 px-2 rounded bg-green-600 text-white"}>Can Receive Texts</span> : <span className={"py-1 px-2 rounded bg-red-600 text-white"}>Sending Only</span>}</td>
                </tr>
                <tr
                  className="items-center divide-x-2 divide-gray-700 hover:bg-gray-900"
                >
                  <td className="px-2 py-2 text-md text-gray-200" colSpan={3}>
                    {serverAddress}<strong>/gateway/{phoneNumber._id}/inbound</strong>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}</tbody>
        </table>
        {phoneNumbers.length === 0 && <AlertMessage.Info>There are no Phone Numbers configured</AlertMessage.Info>}
      </div>
    </div>
  );
}
export default Gateway_PhoneNumberList;