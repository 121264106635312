import AppConfig from "../_appConfig";
import { APIRequest } from "@liquidcomputing/sarstuff__reactjs-singlesignon";

const server = AppConfig.api_server;

const API = {
  _getBuildNumber: () =>
    APIRequest.get(server + `/_buildNumber`).then((res) => res.data),
  dash: () =>
    APIRequest.get(server + `/management/dash`).then((res) => res.data),
  sms: {
    getAvailablePhoneNumbers: (isV2) =>
      APIRequest.get(server + `/sms/available_numbers` + (isV2 ? "?v2=true" : "")).then((res) => res.data),
  },
  data_services: {
    postcode_lookup: (postcode) =>
      APIRequest.get(
        server + `/data_services/postcode_lookup?postcode=${postcode}`
      ).then((res) => res.data),
  },
  authentication: {
    password_policy: () =>
      APIRequest.get(server + `/../authentication/password_policy`).then(
        (res) => res.data
      ),
  },
  org: {
    dashboard: {
      stats: (org_id) => APIRequest.get(server + `/org/${org_id}/dashboard/stats`).then(res => res.data),
      auditLogs: (org_id) => APIRequest.get(server + `/org/${org_id}/dashboard/audit_logs`).then(res => res.data),
      sarsys_stats: org_id => APIRequest.get(server + `/org/${org_id}/dashboard/sarsys_stats`).then(res => res.data),
      locateme_stats: org_id => APIRequest.get(server + `/org/${org_id}/dashboard/locateme_stats`).then(res => res.data)
    },
    all: () => APIRequest.get(server + `/org`).then(res => res.data),
    allSmsStatus: () => APIRequest.patch(server + `/org/sms_status`, {}).then(res => res.data),
    addFromWizard: (data) => APIRequest.post(server + `/org/wizard_add`, data).then(res => res.data),
    show: (org_id) => APIRequest.get(server + `/org/${org_id}`).then(res => res.data),
    update: (org_id, data) => APIRequest.patch(server + `/org/${org_id}`, data).then(res => res),
    updateSettings: (org_id, data) => APIRequest.post(server + `/org/${org_id}/settings`, data).then(res => res.data),
    logo: {
      getUploadConfig: (org_id) => APIRequest.get(`${server}/org/${org_id}/logo/_uploadConfig`).then(res => res.data),
      upload: (org_id, data) => APIRequest.patch(server + `/org/${org_id}/logo/upload`, data, { headers: { "Content-Type": "multipart/form-data" } }),
      getPublicLink: (org_id) => APIRequest.get(`${server}/org/${org_id}/logo/link`).then(res => res.data),
    },


    users: {
      add: (org_id, data) =>
        APIRequest.post(server + `/org/${org_id}/users`, data).then(
          (res) => res.data
        ),
      all: (org_id) =>
        APIRequest.get(server + `/org/${org_id}/users`).then((res) => res.data),
      show: (org_id, user_id) =>
        APIRequest.get(server + `/org/${org_id}/users/${user_id}`).then(
          (res) => res.data
        ),
      update: (org_id, user_id, data) =>
        APIRequest.patch(server + `/org/${org_id}/users/${user_id}`, data).then(
          (res) => res
        ),
      updateStatus: (org_id, user_id, data) =>
        APIRequest.patch(
          server + `/org/${org_id}/users/${user_id}/status`,
          data
        ).then((res) => res.data),
      changePassword: (org_id, user_id, data) =>
        APIRequest.patch(
          server + `/org/${org_id}/users/${user_id}/change_password`,
          data
        ).then((res) => res.data),
      getPhoto: (org_id, user_id) => APIRequest.get(server + `/org/${org_id}/users/${user_id}/photo`).then((res) => res.data),
      auditLogs: {
        recent: (org_id, user_id) =>
          APIRequest.get(
            server + `/org/${org_id}/users/${user_id}/audit_logs/recent`
          ).then((res) => res.data),
      },
    },
    contact: {
      all: (org_id) =>
        APIRequest.get(server + `/org/${org_id}/contact`).then(
          (res) => res.data
        ),
      add: (org_id, data) =>
        APIRequest.post(server + `/org/${org_id}/contact`, data).then(
          (res) => res.data
        ),
      update: (org_id, id, data) =>
        APIRequest.patch(server + `/org/${org_id}/contact/${id}`, data).then(
          (res) => res.data
        ),
      getOrganisations: (org_id) =>
        APIRequest.get(server + `/org/${org_id}/contact/orgs`).then(
          (res) => res.data
        ),
    },
    sms: {
      getDetails: (org_id) => APIRequest.get(server + `/org/${org_id}/sms`).then((res) => res.data),
      buyCredits: (org_id, data) => APIRequest.post(server + `/org/${org_id}/sms/buy_credits`, data).then((res) => res.data),
      getCreditFee: (org_id) => APIRequest.get(server + `/org/${org_id}/sms/credit_fee`).then((res) => res.data),
      getCreditInvoices: (org_id) => APIRequest.get(server + `/org/${org_id}/sms/credit_invoices`).then((res) => res.data),
      getCreditInvoice: (org_id, id) => APIRequest.get(server + `/org/${org_id}/sms/credit_invoices/${id}`).then((res) => res.data),
      getSentMessages: (org_id) => APIRequest.get(server + `/org/${org_id}/sms/sent_messages`).then((res) => res.data),
      getStats: (org_id) => APIRequest.get(server + `/org/${org_id}/sms/stats`).then((res) => res.data),
      changeSender: (org_id, phoneNumberId) => APIRequest.patch(server + `/org/${org_id}/sms/change_sender`, {phoneNumberId}).then((res) => res.data),
      inbox: {
        show: (org_id, inbox_id) =>
          APIRequest.get(server + `/org/${org_id}/sms/inbox/${inbox_id}`).then(
            (res) => res.data
          ),
        messages: (org_id, inbox_id) =>
          APIRequest.get(
            server + `/org/${org_id}/sms/inbox/${inbox_id}/messages`
          ).then((res) => res.data),
      }
    },
    groups: {
      add: (org_id, data) =>
        APIRequest.post(server + `/org/${org_id}/groups`, data).then(
          (res) => res.data
        ),
      all: (org_id) =>
        APIRequest.get(server + `/org/${org_id}/groups`).then(
          (res) => res.data
        ),
      show: (org_id, id) =>
        APIRequest.get(server + `/org/${org_id}/groups/${id}`).then(
          (res) => res.data
        ),
      update: (org_id, id, data) =>
        APIRequest.patch(server + `/org/${org_id}/groups/${id}`, data).then(
          (res) => res.data
        ),
      users: {
        all: (org_id, group_id) =>
          APIRequest.get(
            server + `/org/${org_id}/groups/${group_id}/users`
          ).then((res) => res.data),
        add: (org_id, group_id, user_id) =>
          APIRequest.post(server + `/org/${org_id}/groups/${group_id}/users`, {
            _id: user_id,
          }).then((res) => res.data),
        remove: (org_id, group_id, id) =>
          APIRequest.delete(
            server + `/org/${org_id}/groups/${group_id}/users/${id}`
          ).then((res) => res.data),
      },
    },
    settings: {
      entra_id: {
        getConsentUrl: org_id => APIRequest.get(server + `/org/${org_id}/settings/entra_id/_consentUrl`).then((res) => res.data),
        getDefault: org_id => APIRequest.get(server + `/org/${org_id}/settings/entra_id/default_method`).then((res) => res.data),
        setDefault: (org_id, data) => APIRequest.patch(server + `/org/${org_id}/settings/entra_id/default_method`, data).then((res) => res.data),

        getConfig: (org_id) => APIRequest.get(server + `/org/${org_id}/settings/entra_id/config`).then((res) => res.data),
        setConfig: (org_id, data) => APIRequest.patch(server + `/org/${org_id}/settings/entra_id/config`, data).then((res) => res.data),

        domains: {
          all: (org_id) => APIRequest.get(server + `/org/${org_id}/settings/entra_id/domains`).then(res => res.data),
          add: (org_id, data) => APIRequest.post(server + `/org/${org_id}/settings/entra_id/domains`, data).then(res => res.data),
          remove: (org_id, id) => APIRequest.delete(server + `/org/${org_id}/settings/entra_id/domains/${id}`).then(res => res.data),
          verify: (org_id, id) => APIRequest.get(server + `/org/${org_id}/settings/entra_id/domains/${id}/verify`).then(res => res.data),
        },
        rules: {
          all: (org_id) => APIRequest.get(server + `/org/${org_id}/settings/entra_id/rules`).then(res => res.data),
          add: (org_id, data) => APIRequest.post(server + `/org/${org_id}/settings/entra_id/rules`, data).then(res => res.data),
          update: (org_id, id, data) => APIRequest.patch(server + `/org/${org_id}/settings/entra_id/rules/${id}`, data).then(res => res.data),
          remove: (org_id, id) => APIRequest.delete(server + `/org/${org_id}/settings/entra_id/rules/${id}`).then(res => res.data),
        }
      },
      systems: {
        getConfig: (org_id, system_id) =>
          APIRequest.get(
            server + `/org/${org_id}/settings/systems/${system_id}`
          ).then((res) => res.data),
        setConfig: (org_id, system_id, data) =>
          APIRequest.patch(
            server + `/org/${org_id}/settings/systems/${system_id}`,
            data
          ).then((res) => res.data),
        getSSOApplications: (org_id, system_id) =>
          APIRequest.get(
            server +
              `/org/${org_id}/settings/systems/${system_id}/sso_applications`
          ).then((res) => res.data),
      },
      ciem: {
        notify_email: {
          getAll: (org_id) => APIRequest.get(`${server}/org/${org_id}/settings/ciem/notify_emails`).then(res => res.data),
          create: (org_id, data) => APIRequest.post(`${server}/org/${org_id}/settings/ciem/notify_emails`, data).then(res => res.data),
          update: (org_id, _id, data) => APIRequest.patch(`${server}/org/${org_id}/settings/ciem/notify_emails/${_id}`, data).then(res => res.data),
          remove: (org_id, _id) => APIRequest.delete(`${server}/org/${org_id}/settings/ciem/notify_emails/${_id}`).then(res => res.data),
        },
      }
    },
    feeds: {
      createAsset: (org_id, name) => APIRequest.post(server + `/org/${org_id}/feeds/assets`, { name }).then((res) => res.data),
      getAssets: (org_id) => APIRequest.get(server + `/org/${org_id}/feeds/assets`).then((res) => res.data),
      getAsset: (org_id, asset_id) => APIRequest.get(server + `/org/${org_id}/feeds/assets/${asset_id}`).then((res) => res.data),
      updateAsset: (org_id, asset_id, data) => APIRequest.patch(server + `/org/${org_id}/feeds/assets/${asset_id}`, data).then((res) => res.data),
      deleteAsset: (org_id, asset_id) => APIRequest.delete(server + `/org/${org_id}/feeds/assets/${asset_id}`).then((res) => res.data),

      createAssetFeed: (org_id, asset_id, alias) => APIRequest.post(server + `/org/${org_id}/feeds/assets/${asset_id}/feeds`, { alias }).then((res) => res.data),
      getAssetFeeds: (org_id, asset_id) => APIRequest.get(server + `/org/${org_id}/feeds/assets/${asset_id}/feeds`).then((res) => res.data),
      getAssetFeedRtmp: (org_id, asset_id, feed_id) => APIRequest.get(server + `/org/${org_id}/feeds/assets/${asset_id}/feeds/${feed_id}/rtmp`).then((res) => res.data),
      deleteAssetFeed: (org_id, asset_id, feed_id) => APIRequest.delete(server + `/org/${org_id}/feeds/assets/${asset_id}/feeds/${feed_id}`).then(res => res.data),
    },
    sarsys_request: {
      allowJobsFrom: {
        pickable: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/organisations/pickable`).then(res => res.data),
        pickedOrganisations: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/organisations`).then(res => res.data),
        addOrganisation: (org_id, pickedOrg) => APIRequest.post(`${server}/org/${org_id}/sarsys_request/organisations`, {orgId: pickedOrg}).then(res => res.data),
        removeOrganisation: (org_id, pickedOrg) => APIRequest.delete(`${server}/org/${org_id}/sarsys_request/organisations/${pickedOrg}`).then(res => res.data),
      },
      jobTemplate: {
        pickable: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/templates/pickable`).then(res => res.data),
        getTemplates: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/templates`).then(res => res.data),
        addTemplate: (org_id, template) => APIRequest.post(`${server}/org/${org_id}/sarsys_request/templates`, {template}).then(res => res.data),
        removeTemplate: (org_id, template) => APIRequest.delete(`${server}/org/${org_id}/sarsys_request/templates/${template}`).then(res => res.data)
      },
      notify_email: {
        getAll: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/notify_emails`).then(res => res.data),
        create: (org_id, data) => APIRequest.post(`${server}/org/${org_id}/sarsys_request/notify_emails`, data).then(res => res.data),
        update: (org_id, _id, data) => APIRequest.patch(`${server}/org/${org_id}/sarsys_request/notify_emails/${_id}`, data).then(res => res.data),
        remove: (org_id, _id) => APIRequest.delete(`${server}/org/${org_id}/sarsys_request/notify_emails/${_id}`).then(res => res.data),
      },
      notify_sms: {
        getAll: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/notify_sms`).then(res => res.data),
        pickable: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_request/notify_sms/pickable`).then(res => res.data),
        create: (org_id, data) => APIRequest.post(`${server}/org/${org_id}/sarsys_request/notify_sms`, data).then(res => res.data),
        update: (org_id, _id, data) => APIRequest.patch(`${server}/org/${org_id}/sarsys_request/notify_sms/${_id}`, data).then(res => res.data),
        remove: (org_id, _id) => APIRequest.delete(`${server}/org/${org_id}/sarsys_request/notify_sms/${_id}`).then(res => res.data),
      }
    },
    sarsys_review: {
      organisations: {
        pickable: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_review/organisations/pickable`).then(res => res.data),
        pickedOrganisations: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_review/organisations`).then(res => res.data),
        addOrganisation: (org_id, pickedOrg) => APIRequest.post(`${server}/org/${org_id}/sarsys_review/organisations`, {orgId: pickedOrg}).then(res => res.data),
        removeOrganisation: (org_id, pickedOrg) => APIRequest.delete(`${server}/org/${org_id}/sarsys_review/organisations/${pickedOrg}`).then(res => res.data),
      },
      notify_email: {
        getAll: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_review/notify_emails`).then(res => res.data),
        create: (org_id, data) => APIRequest.post(`${server}/org/${org_id}/sarsys_review/notify_emails`, data).then(res => res.data),
        update: (org_id, _id, data) => APIRequest.patch(`${server}/org/${org_id}/sarsys_review/notify_emails/${_id}`, data).then(res => res.data),
        remove: (org_id, _id) => APIRequest.delete(`${server}/org/${org_id}/sarsys_review/notify_emails/${_id}`).then(res => res.data),
      },
      notify_sms: {
        getAll: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_review/notify_sms`).then(res => res.data),
        pickable: (org_id) => APIRequest.get(`${server}/org/${org_id}/sarsys_review/notify_sms/pickable`).then(res => res.data),
        create: (org_id, data) => APIRequest.post(`${server}/org/${org_id}/sarsys_review/notify_sms`, data).then(res => res.data),
        update: (org_id, _id, data) => APIRequest.patch(`${server}/org/${org_id}/sarsys_review/notify_sms/${_id}`, data).then(res => res.data),
        remove: (org_id, _id) => APIRequest.delete(`${server}/org/${org_id}/sarsys_review/notify_sms/${_id}`).then(res => res.data),
      }
    },
    cctv: {
      getAll: (org_id) =>
        APIRequest.get(server + `/org/${org_id}/cctv`).then((res) => res.data),
      remove: (org_id, cctv_id) =>
        APIRequest.delete(server + `/org/${org_id}/cctv/${cctv_id}`).then(
          (res) => res.data
        ),
    },
    roles: {
      all: (org_id) =>
        APIRequest.get(server + `/org/${org_id}/roles`).then((res) => res.data),
      create: (org_id, data) =>
        APIRequest.post(server + `/org/${org_id}/roles`, data).then(
          (res) => res.data
        ),
      update: (org_id, role_id, data) =>
        APIRequest.patch(server + `/org/${org_id}/roles/${role_id}`, data).then(
          (res) => res.data
        ),
      delete: (org_id, role_id) =>
        APIRequest.delete(server + `/org/${org_id}/roles/${role_id}`).then(
          (res) => res.data
        ),
    },
    sarmap: {
      assets: {
        add: (org_id, data) =>
          APIRequest.post(`${server}/org/${org_id}/sarmap/assets`, data).then(
            (res) => res.data
          ),
        all: (org_id) =>
          APIRequest.get(`${server}/org/${org_id}/sarmap/assets`).then(
            (res) => res.data
          ),
        remove: (org_id, asset_id) =>
          APIRequest.delete(
            `${server}/org/${org_id}/sarmap/assets/${asset_id}`
          ).then((res) => res.data),
        show: (org_id, asset_id) =>
          APIRequest.get(
            `${server}/org/${org_id}/sarmap/assets/${asset_id}`
          ).then((res) => res.data),
        update: (org_id, asset_id, data) =>
          APIRequest.patch(
            `${server}/org/${org_id}/sarmap/assets/${asset_id}`,
            data
          ).then((res) => res.data),
      },
      settings: {
        quartix: {
          getSettings: (org_id) =>
            APIRequest.get(
              `${server}/org/${org_id}/sarmap/settings/quartix`
            ).then((res) => res.data),
          setSettings: (org_id, data) =>
            APIRequest.patch(
              `${server}/org/${org_id}/sarmap/settings/quartix`,
              data
            ).then((res) => res.data),
        },
        zello: {
          getSettings: (org_id) =>
            APIRequest.get(
              `${server}/org/${org_id}/sarmap/settings/zello`
            ).then((res) => res.data),
          setSettings: (org_id, data) =>
            APIRequest.patch(
              `${server}/org/${org_id}/sarmap/settings/zello`,
              data
            ).then((res) => res.data),
        },
      },
    },
    push_notifications: {
      getUsers: (org_id) => APIRequest.get(`${server}/org/${org_id}/push_notifications/users`).then(res => res.data),
      getRoles: (org_id) => APIRequest.get(`${server}/org/${org_id}/push_notifications/roles`).then(res => res.data),
      sendNotification: (org_id, data) => APIRequest.post(`${server}/org/${org_id}/push_notifications/send`, data).then(res => res.data)
    },
    telephone: {
      dashboard: org_id => APIRequest.get(`${server}/org/${org_id}/telephone/dashboard`).then(res => res.data),
      recordings: (org_id) => APIRequest.get(`${server}/org/${org_id}/telephone/recordings`).then(res => res.data),
      recordingLink: (org_id, fileId) => APIRequest.get(`${server}/org/${org_id}/telephone/recordings/${fileId}`).then(res => res.data),
      recordingDelete: (org_id, fileId) => APIRequest.delete(`${server}/org/${org_id}/telephone/recordings/${fileId}`).then(res => res.data),
      voicemails: (org_id) => APIRequest.get(`${server}/org/${org_id}/telephone/voicemails`).then(res => res.data),
      voicemailLink: (org_id, fileId) => APIRequest.get(`${server}/org/${org_id}/telephone/voicemails/${fileId}`).then(res => res.data),
      voicemailDelete: (org_id, fileId) => APIRequest.delete(`${server}/org/${org_id}/telephone/voicemails/${fileId}`).then(res => res.data),
      phone_numbers:{
        all: org_id => APIRequest.get(`${server}/org/${org_id}/telephone/phone_numbers`).then(res => res.data),
        logs: (org_id, phone_id) => APIRequest.get(`${server}/org/${org_id}/telephone/phone_numbers/${phone_id}/logs`).then(res => res.data),
        flow: (org_id, phone_id) => APIRequest.get(`${server}/org/${org_id}/telephone/phone_numbers/${phone_id}/flow`).then(res => res.data),
        updateFlow: (org_id, phone_id, data) => APIRequest.put(`${server}/org/${org_id}/telephone/phone_numbers/${phone_id}/flow`, data).then(res => res.data),
        updateConfig: (org_id, phone_id, data) => APIRequest.patch(`${server}/org/${org_id}/telephone/phone_numbers/${phone_id}/config`, data).then(res => res.data),
      }
    }
  },
  management: {
    sms: {
      _serverUrl: () => APIRequest.get(server + `/management/sms/server_url`).then((res) => res.data),
      applications: {
        getAll: () =>
          APIRequest.get(server + `/management/sms/applications`).then(
            (res) => res.data
          ),
        create: (data) =>
          APIRequest.post(server + `/management/sms/applications`, data).then(
            (res) => res.data
          ),
        get: (appId) =>
          APIRequest.get(server + `/management/sms/applications/${appId}`).then(
            (res) => res.data
          ),
        update: (appId, data) =>
          APIRequest.patch(
            server + `/management/sms/applications/${appId}`,
            data
          ).then((res) => res.data),
      },
      gateways: {
        create: (data) => APIRequest.post(server + `/management/sms/gateway`, data).then(res => res.data),
        getAll: () => APIRequest.get(server + `/management/sms/gateway`).then(res => res.data),
        update: (gatewayId, data) => APIRequest.patch(server + `/management/sms/gateway/${gatewayId}`, data).then((res) => res.data),
        apiKey: {
          retrieve: (gatewayId) => APIRequest.get(server + `/management/sms/gateway/${gatewayId}/api_key`).then(res => res.data),
          update: (gatewayId, apiKey) => APIRequest.patch(server + `/management/sms/gateway/${gatewayId}/api_key`, {apiKey}).then(res => res.data),
        },
        phoneNumbers: {
          create: (gatewayId, data) => APIRequest.post(server + `/management/sms/gateway/${gatewayId}/phone_number`, data).then(res => res.data),
          getAll: (gatewayId) => APIRequest.get(server + `/management/sms/gateway/${gatewayId}/phone_number`).then(res => res.data),
          update: (gatewayId, phoneId, data) => APIRequest.patch(server + `/management/sms/gateway/${gatewayId}/phone_number/${phoneId}`, data).then((res) => res.data),
        },
      },
      organisations: {
        getAll: () =>
          APIRequest.get(server + `/management/sms/organisations`).then(
            (res) => res.data
          ),
        create: (data) =>
          APIRequest.post(server + `/management/sms/organisations`, data).then(
            (res) => res.data
          ),
        get: (organisationId) =>
          APIRequest.get(
            server + `/management/sms/organisations/${organisationId}`
          ).then((res) => res.data),
        update: (organisationId, data) =>
          APIRequest.patch(
            server + `/management/sms/organisations/${organisationId}`,
            data
          ).then((res) => res.data),
        applications: {
          getAll: (organisationId) =>
            APIRequest.get(
              server +
                `/management/sms/organisations/${organisationId}/applications`
            ).then((res) => res.data),
          assign: (organisationId, appId) =>
            APIRequest.post(
              server +
                `/management/sms/organisations/${organisationId}/applications`,
              { id: appId }
            ).then((res) => res.data),
          unassign: (organisationId, appId) =>
            APIRequest.delete(
              server +
                `/management/sms/organisations/${organisationId}/applications/${appId}`
            ).then((res) => res.data),
        },
        credits: {
          adjustmentLog: (orgId) => APIRequest.get(server + `/management/sms/organisation/${orgId}/credits/adjustment_log`).then((res) => res.data),
          adjust: (orgId, data) => APIRequest.patch(server + `/management/sms/organisation/${orgId}/credits/adjust`, data).then(res => res.data),
          add: (organisationId, credits) =>
            APIRequest.patch(
              server +
                `/management/sms/organisations/${organisationId}/credits/add`,
              { credits }
            ).then((res) => res.data),
          remove: (organisationId, credits) =>
            APIRequest.patch(
              server +
                `/management/sms/organisations/${organisationId}/credits/remove`,
              { credits }
            ).then((res) => res.data),
        },
        messages: {
          received: (organisationId) =>
            APIRequest.get(
              server +
                `/management/sms/organisations/${organisationId}/messages/received`
            ).then((res) => res.data),
          sent: (organisationId) =>
            APIRequest.get(
              server +
                `/management/sms/organisations/${organisationId}/messages/sent`
            ).then((res) => res.data),
        },
        phoneNumbers: {
          // getAll: (organisationId) => APIRequest.get(server + `/management/sms/organisations/${organisationId}/phone_numbers`).then((res) => res.data),
          assign: (organisationId, phoneNumberId) => APIRequest.post(server + `/management/sms/organisation/${organisationId}/phone_numbers/assign`, { phoneNumberId }).then((res) => res.data),
          unassign: (organisationId, phoneNumberId) => APIRequest.delete(server + `/management/sms/organisation/${organisationId}/phone_numbers/${phoneNumberId}/unassign`).then((res) => res.data),
        },
      },
    },
  },
  logs: {
    all: () =>
      APIRequest.get(server + `/management/audit_logs/latest`).then(
        (res) => res.data
      ),
    eventLogs: () =>
      APIRequest.get(server + `/management/event_logs/latest`).then(
        (res) => res.data
      ),
  },
  settings: {
    all: () =>
      APIRequest.get(server + `/settings/system`).then((res) => res.data),
    update: (data) =>
      APIRequest.patch(server + `/settings/system`, data).then(
        (res) => res.data
      ),
  },
  applications: {
    all: () => APIRequest.get(server + `/applications`).then((res) => res.data),
    add: (data) => APIRequest.post(server + `/applications`, data).then((res) => res.data),
    show: (id) => APIRequest.get(server + `/applications/${id}`).then((res) => res.data),
    update: (id, data) => APIRequest.patch(server + `/applications/${id}`, data).then((res) => res.data),
    logs: (id) => APIRequest.get(server + `/applications/${id}/logs`).then((res) => res.data),
    callbacks: {
      list: (id) => APIRequest.get(server + `/applications/${id}/callbacks`).then((res) => res.data),
      add: (id, url) => APIRequest.post(server + `/applications/${id}/callbacks`, { url }).then((res) => res.data),
      edit: (id, callbackId, url) => APIRequest.patch(server + `/applications/${id}/callbacks/${callbackId}`, { url }).then((res) => res.data),
      remove: (id, callbackId) => APIRequest.delete(server + `/applications/${id}/callbacks/${callbackId}`).then((res) => res.data),
    },
    mobile: {
      all: () => APIRequest.get(`${server}/applications/mobile`).then((res) => res.data)
    }
  },
  systems: {
    all: () => APIRequest.get(server + "/systems").then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/systems", data).then((res) => res.data),
    get: (id) =>
      APIRequest.get(server + "/systems/" + id).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + "/systems/" + id, data).then((res) => res.data),
  },
};

export default API;