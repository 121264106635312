import React, {useEffect, useState} from "react";
import readXlsxFile from 'read-excel-file'
import {Organisation_Receiver} from "../../../../context/organisation";
import ReturnHeadingBar from "../../../../shared/components/ReturnHeadingBar";
import FileUploadSelector from "../../../../shared/components/ui-wrappers/fileUploadSelector";
import Loader from "../../../../shared/components/Loader";
import Select from "../../../../shared/components/Select";
import API from "../../../../shared/API";

const dataFields = [
    {title: "Firstname", field: "firstName", mapping: ["firstname", "first_name", "First Name"], isRequired: true, validate: async (data) => {
        data = "" + data;
        return data.trim().length > 0;
    }},
    {title: "Surname", field: "surname", mapping: ["surname", "lastname", "last_name", "Last Name"], isRequired: true, validate: async (data) => {
        data = "" + data;
        return data.trim().length > 0;
    }},
    {title: "Email Address", field: "email", mapping: ["email", "emailaddress", "email_address", "Email"], isRequired: true, validate: async (data) => {
        data = "" + data;
        return data.trim().length > 0; // TODO link this to check to ensure that no email addresses already exist
    }},
    {title: "Is Operational", field: "operational", mapping: ["operational", "isoperational", "is_operational", "Operational"], isRequired: true, validate: async (data) => {
        data = "" + data;
        return data.toLowerCase() === "y" || data.toLowerCase() === "yes" || data.toLowerCase() === "n" || data.toLowerCase() === "no";
    }},
    {title: "Is On-Call", field: "onCall", mapping: ["onCall", "isoncall", "is_oncall", "on-call"], isRequired: true, validate: async (data) => {
        data = "" + data;
        return data.toLowerCase() === "y" || data.toLowerCase() === "yes" || data .toLowerCase()=== "n" || data.toLowerCase() === "no";
    }},
    {title: "Primary Phone", field: "phone", mapping: ["phone", "primaryphone", "phoneprimary", "primary_phone", "phone_primary", "Mobile Phone"], isRequired: true, validate: async (data) => {
        data = "" + data;
        return (data.substring(0, 2) === "44" || data.substring(0, 2) === "07") && data.length > 8
    }},
    {title: "Secondary Phone", field: "phoneSecondary", mapping: ["secondaryphone", "phonesecondary", "secondary_phone", "phone_secondary"], isRequired: false, validate: async (data) => {
        data = "" + data;
        return (data.substring(0, 2) === "44" || data.substring(0, 2) === "07") && data.length > 8
    }},
    {title: "Call Sign", field: "teamId", mapping: ["callsign", "call_sign"], isRequired: false, validate: async (data) => {
        return true
    }},
    {title: "Home Postcode", field: "_postcode", mapping: ["postcode", "Postcode"], isRequired: false, validate: async (data) => {
        return true
    }},
];

const UserImporter = ({organisation}) => {
    const [excelFile, setExcelFile] = useState({});
    const [isImporting, setIsImporting] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [errorLoadingExcel, setErrorLoadingExcel] = useState(false);
    const [isValidationRunning, setIsValidationRunning] = useState(false);
    const [dataValidationPassed, setDataValidationPassed] = useState(false);
    const [mappedHeaderValid, setMappedHeaderValid] = useState(false);
    const [rawExcelRows, setRawExcelRows] = useState([]);
    const [rawHeaderRow, setRawHeaderRow] = useState([]);
    const [mappedHeader, setMappedHeader] = useState({});
    const [importLog, setImportLog] = useState([]);
    const [progressCount, setProgressCount] = useState(0);

    useEffect(() => {
        console.log("excelFile", excelFile);

        if(excelFile.name !== undefined){
            setLoadingExcel(true);
            setErrorLoadingExcel(false);

            readXlsxFile(excelFile).then((rows) => {
                setRawHeaderRow(rows[0]);
                setRawExcelRows(rows.filter((a, i) => i !== 0));
            }).catch(err => {
                setExcelFile({});
                setErrorLoadingExcel(true);
            }).finally(() => {
                setLoadingExcel(false);
            });
        }
    }, [excelFile]);
    useEffect(() => {
        let newMappingResult = {};
        for(const i in dataFields){
            const a = dataFields[i];

            let foundColumn = "";
            for(const ii in a.mapping){
                const check = a.mapping[ii]
                if(rawHeaderRow.indexOf(check) !== -1){
                    foundColumn = check;
                    break;
                }
            }

            newMappingResult[a.field] = foundColumn;
        }
        setMappedHeader(newMappingResult);
    }, [rawHeaderRow]);
    useEffect(() => {
        if(Object.keys(mappedHeader).length === 0){
            setMappedHeaderValid(false);
            return;
        }

        let isValid = true;
        for(const i in dataFields.filter(b => b.isRequired)){
            const a = dataFields[i];
            if(mappedHeader[a.field].trim() === ""){
                isValid = false;
            }
        }
        setMappedHeaderValid(isValid);
    }, [mappedHeader]);

    const headerNameToIndex = name => {
        return rawHeaderRow.indexOf(name);
    }
    const mapData = row => {
        let data = {};
        for(const i in dataFields){
            const a = dataFields[i];
            let theData = null;
            if(mappedHeader[a.field].trim() !== ""){
                theData = row[headerNameToIndex(mappedHeader[a.field])];
            }
            data[a.field] = theData;
        }
        return data;
    }
    const validateData = async () => {
        let errs = [];
        setIsValidationRunning(true);

        try {
            for(const index in rawExcelRows){
                const rowData = mapData(rawExcelRows[index]);
                for(const i in dataFields){
                    const a = dataFields[i];
                    const val = rowData[a.field];
                    const validResult = await a.validate(val);
                    if(!validResult){
                        if(!a.isRequired && val === null) continue;
                        if(!a.isRequired && val === "") continue;

                        errs.push({
                            row: parseInt(index) + 1,
                            error: "Data field " + a.title + " does not pass the validation rule, value: " + val
                        });
                    }
                }
                console.log("rowData", index, rowData);
            }
        } catch(e){
            console.error(e);
            errs.push({
                row: "N/A",
                error: "Validation checked failed. " + e.message
            });
        }

        setImportLog(errs.map(a => {
            return "[ERROR] ROW:" + a.row + " - " + a.error;
        }));
        setDataValidationPassed(errs.length === 0);
        setIsValidationRunning(false);
        setIsImporting(false);
    }
    const importUsers = async () => {
        setImportLog([]);
        setIsImporting(true);
        setProgressCount(0);

        let tempLogs = [];
        for(const index in rawExcelRows){
            const rowData = mapData(rawExcelRows[index]);
            const rowNumber = (parseInt(index) + 2);

            let location = {
                type: "Feature",
                geometry: {
                    coordinates: [],
                    type: "Point",
                },
                properties: {},
            };
            if(rowData._postcode !== undefined && rowData._postcode !== null && rowData._postcode.length >= 3){
                try {
                    const postcodeLookup = await API.data_services.postcode_lookup(rowData._postcode);
                    if(postcodeLookup[0] !== undefined){
                        location = {
                            type: "Feature",
                            geometry: postcodeLookup[0].geometry,
                            properties: {
                                ...postcodeLookup[0].properties,
                                postcode: postcodeLookup[0].postcode,
                            },
                        }
                    } else {
                        tempLogs.push("[WARNING] ROW:" + rowNumber + " Failed to lookup POSTCODE:" + rowData._postcode + " due to error: NO_RECORDS_FOUND");
                    }
                } catch(e){
                    tempLogs.push("[WARNING] ROW:" + rowNumber + " Failed to lookup POSTCODE:" + rowData._postcode + " due to error: " + (e?.response?.data?.message || e.message));
                }
            }


            try {
                let res = await API.org.users.add(organisation.data._id, {
                    ...rowData,
                    location,
                    operational: rowData.operational.toLowerCase() === "y" || rowData.operational.toLowerCase() === "yes",
                    onCall: rowData.onCall.toLowerCase() === "y" || rowData.onCall.toLowerCase() === "yes",
                    password: "",
                    isAutoPassword: true
                });
                tempLogs.push("[CREATED] Email: " + rowData.email + " Password: " + (res?.password || ""));
            } catch(e){
                console.error(e);
                tempLogs.push("[ERROR] ROW:" + rowNumber + " Failed to create users because of error: " + (e?.response?.data?.message || e.message));
            }

            setProgressCount(curr => curr + 1);
        }
        setImportLog(tempLogs);
        setIsImporting(false);
    }

    return (
        <div>
            <ReturnHeadingBar title={"Bulk User Importer"} linkTo={`${organisation._urlPrefix}/team`} />

            <div className="p-4 w-full">
                <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
                    Step One - Upload Excel Spreadsheet
                </div>
                <div className="gap-3 border border-gray-700 bg-gray-900 px-2 py-4 rounded-b-xl">
                    {errorLoadingExcel && <div className={"p-2 mb-4 bg-red-700"}>Unable to load the selected excel document due to a loading error. Either it is corrupt or the document is encrypted/protected.</div>}
                    {excelFile.name !== undefined ? <React.Fragment>
                        <p>Selected Excel File: <strong>{excelFile.name}</strong></p>
                        <p>Total Rows: <strong>{rawExcelRows.length}</strong></p>
                        <p>Header Columns: <strong>{rawHeaderRow.join(", ")}</strong></p>

                        <button onClick={() => setExcelFile({})} className="mt-2 px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Change File</button>
                    </React.Fragment> : <React.Fragment>
                        {loadingExcel && <Loader>Reading file...</Loader>}
                        {!loadingExcel && <FileUploadSelector onFileSelected={file => setExcelFile(file[0] !== undefined ? file[0] : {})} />}
                    </React.Fragment>}
                </div>
            </div>
            {excelFile.name !== undefined && <div className="p-4 w-full">
                <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
                    Step Two - Map Columns To Data Fields
                </div>
                <div className="gap-3 border border-gray-700 bg-gray-900 px-2 py-4 rounded-b-xl">
                    <p>Please now map the columns in the Excel file to there related data field, any we could automatically match we have already done for you.</p>

                    <table className="border-separate w-full">
                        <thead>
                            <tr>
                                <th className="text-left px-2 py-2 bg-gray-900">Data Field</th>
                                <th className="text-left px-2 py-2 bg-gray-900">Is Required</th>
                                <th className="text-left px-2 py-2 bg-gray-900">Excel Column</th>
                            </tr>
                        </thead>
                        <tbody>{dataFields.map((item, i) => {
                            return (<tr key={i}>
                                <td className="border border-gray-700 text-left px-4">{item.title}</td>
                                <td className="border border-gray-700 text-left px-4">{item.isRequired ? "Yes" : "No"}</td>
                                <td className="border border-gray-700 text-left p-4">
                                    <Select
                                        field={"areaType"}
                                        value={mappedHeader[item.field] !== undefined ? mappedHeader[item.field] : ""}
                                        // error={errors.areaType ? errors.areaType : ""}
                                        onChange={(event) => {
                                            setMappedHeader({...mappedHeader, [item.field]: event.target.value});
                                            setDataValidationPassed(false);
                                        }}
                                        options={[].concat(rawHeaderRow.map(a => {
                                            return {key: a, text: a, value: a}
                                        }))}
                                    />
                                </td>
                            </tr>);
                        })}</tbody>
                    </table>

                    {!mappedHeaderValid && <div className={"text-center text-blue-300 text-lg border m-2 p-2 rounded border-blue-700"}>
                        Please complete the Data Field to Excel Column mapping above to continue.
                    </div>}
                </div>
            </div>}
            {mappedHeaderValid && <div className="p-4 w-full">
                <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
                    Step Three - Check Excel Data For Errors
                </div>
                <div className="gap-3 border border-gray-700 bg-gray-900 px-2 py-4 rounded-b-xl">
                    {!dataValidationPassed && <p>Time to check that the data being imported does not contain any errors that will prevent it from being imported. This process may take a while depending on the amount of data you are importing.</p>}

                    {!dataValidationPassed && !isValidationRunning && <button onClick={() => validateData()} className="mt-2 px-4 py-2 bg-green-900 text-white tracking-wider border border-gray-700 rounded-xl">Check Data</button>}
                    {!dataValidationPassed && isValidationRunning && <Loader>Checking data...</Loader>}
                    {dataValidationPassed && <p>Data validation passed, please proceed with the import below.</p>}
                </div>
            </div>}
            {dataValidationPassed && <div className="p-4 w-full">
                <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
                    Step Four - Import User Accounts
                </div>
                <div className="gap-3 border border-gray-700 bg-gray-900 px-2 py-4 rounded-b-xl">
                    {!isImporting && <button onClick={() => importUsers()} className="mt-2 px-4 py-2 bg-green-900 text-white tracking-wider border border-gray-700 rounded-xl">Import Users</button>}
                    {isImporting && <Loader>Importing Users... {progressCount} of {rawExcelRows.length}</Loader>}
                </div>
            </div>}

            <div className={"mx-4 py-4 border-t-2"}>
                <label>Importer Log</label>
                <textarea rows={15} className={"w-full border rounded text-black p-2"} readOnly={true} value={importLog.join("\n")} />
            </div>
        </div>
    );
}
export default Organisation_Receiver(UserImporter,(organisation) => {
    return { organisation };
});