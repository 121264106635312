import React, {useEffect, useState} from "react";
import API from "../../../../../../shared/API";
import Field_DropdownInput from "../../../../../../shared/components/Field_DropdownInput";
import Field_TextInput from "../../../../../../shared/components/Field_TextInput";

const initialFormValues = {
  title: "",
  provider: "",
  isSarStuff: true,
  organisation: ""
};

const smsGatewayProviders = [
  { text: "TextLocal/BT", value: "TextLocal", id: 1 },
  { text: "Twilio", value: "Twilio", id: 2 },
  { text: "Quick SMS", value: "QuickSMS", id: 3 },
]

const GatewayForm = ({ onSubmit, isEdit = false, values = {}}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({ ...initialFormValues, ...values });
  const [errors, setErrors] = useState({});
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    API.org.all().then(res => {
      setOrganisations(res.filter(a => !a.system_settings.is_account_disabled).sort((a,b) => {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }).map(org => {
        return {
          id: org._id,
          value: org._id,
          text: org.name
        }
      }))
    });
  }, []);
  useEffect(() => {
    if(data.isSarStuff && data.organisation !== ""){
      setData({
        ...data,
        organisation: ""
      })
    }

    setErrors(handleFormValidation());
  }, [data]);

  const handleFormValidation = () => {
    const errors = {};

    if (!data.title) errors.title = "Can't be empty";
    if (!data.provider) errors.provider = "Provider must be selected";
    if (!data.isSarStuff){
      if(!data.organisation){
        errors.organisation = "Organisation must be selected";
      }
    }

    return errors;
  };

  const handleGatewaySubmit = () => {
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      onSubmit(data).catch(() => {
        setIsSubmitting(false)
      });
    }
  }

  return (
    <div className={"w-full"}>
      {isSubmitting && <div className={"p-2 bg-gray-700"}>{isEdit ? "Updating" : "Creating new"} SMS Gateway...</div>}
      <div className="p-2 flex gap-2 w-full">
        <div className="flex flex-col gap-2 w-full">
          <Field_TextInput
            title={"Title"}
            name={"title"}
            value={data.title}
            onChange={val => setData({ ...data, title: val })}
            isDisabled={isSubmitting}
            error={errors.title}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Field_DropdownInput
            title={"Provider"}
            name={"provider"}
            value={data.provider}
            options={smsGatewayProviders}
            onChange={val => setData({ ...data, provider: val })}
            isDisabled={isSubmitting}
            error={errors.provider}
          />
        </div>
      </div>
      <div className="p-2 flex gap-2 w-full">
        <div className="flex flex-col gap-2 w-full">
          <Field_DropdownInput
            title={"Is SarStuff Owned"}
            name={"isSarStuff"}
            value={data.isSarStuff ? "true": "false"}
            options={[
              { text: "Yes", value: "true", id: 1},
              { text: "No", value: "false", id: 0}
            ]}
            onChange={val => setData({ ...data, isSarStuff: (val === "true") })}
            isDisabled={isSubmitting}
            error={errors.isSarStuff}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          {!data.isSarStuff ? <Field_DropdownInput
            title={"Organisation"}
            name={"organisation"}
            value={data.organisation}
            options={organisations}
            onChange={val => setData({ ...data, organisation: val })}
            isDisabled={isSubmitting}
            error={errors.organisation}
          /> : ""}
        </div>
      </div>
      <div className="flex w-full border-t border-gray-700 mt-8 p-4">
        <button
          onClick={handleGatewaySubmit}
          disabled={Object.keys(errors).length > 0}
          className={`${Object.keys(errors).length > 0
            ? "cursor-not-allowed bg-green-900 bg-opacity-40 text-gray-700 border-green-800 "
            : "bg-green-700 hover:bg-opacity-80 border-green-300 text-white "
          } rounded-xl border p-2 w-full`}
        >{isEdit ? "Update" : "Create"}</button>
      </div>
    </div>
  );
}
export default GatewayForm;