import React from "react";
import toast from "react-hot-toast";
import API from "../../../../../shared/API";
import ReturnHeadingBar from "../../../../../shared/components/ReturnHeadingBar";
import GatewayForm from "./_components/GatewayForm";

const CreateGateway = ({history}) => {

  const handleCreateGateway = (data) => {
    return API.management.sms.gateways.create(data).then(() => {
      toast.success("Successfully created the new SMS Gateway", {
        duration: 5000,
      });
      setTimeout(() => {
        history.push("/sms/gateways");
      }, 1000);
    }).catch(error => {
      let errorMessage = "There was an unexpected error while adding new SMS Gateway";
      toast.error(errorMessage, {
        duration: 5000,
      });
    })
  }

  return (
    <div className="p-2">
      <ReturnHeadingBar
        linkTo={`/sms/gateways`}
      />
      <div className={"mt-2"}>
        <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">Create Gateway</div>
        <div className="px-4 pb-2 pt-2 border border-gray-700  rounded-b-xl">
          <GatewayForm onSubmit={handleCreateGateway}/>
        </div>
      </div>
    </div>
  );
}
export default CreateGateway;