import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import API from "../../../../../shared/API";
import Loader from "../../../../../shared/components/Loader";
import ReturnHeadingBar from "../../../../../shared/components/ReturnHeadingBar";
import GatewayForm from "./_components/GatewayForm";

const EditGateway = ({history}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [gateway, setGateway]     = useState({});

  const {gateway_id} = useParams();

  useEffect(() => {
    getData();
  }, [gateway_id]);

  const getData = () => {
    API.management.sms.gateways.getAll().then(res => {
      const theGateway = res.filter(a => a._id === gateway_id);
      setGateway(theGateway[0] ? theGateway[0] : {});
      setIsLoading(false);
    })
  }

  const handleUpdateGateway = (data) => {
    return API.management.sms.gateways.update(gateway_id, data).then(() => {
      toast.success("Successfully updated the SMS Gateway", {
        duration: 5000,
      });
      setTimeout(() => {
        history.push("/sms/gateways/" + gateway_id);
      }, 1000);
    }).catch(error => {
      let errorMessage = "There was an unexpected error while updating the SMS Gateway";
      toast.error(errorMessage, {
        duration: 5000,
      });
    })
  };

  if (isLoading){
    return (
      <Loader>Loading Gateway...</Loader>
    );
  }

  return (
    <div className="p-2">
      <ReturnHeadingBar
        linkTo={`/sms/gateways`}
      />
      <div className={"mt-2"}>
        <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">Update Gateway</div>
        <div className="px-4 pb-2 pt-2 border border-gray-700  rounded-b-xl">
          <GatewayForm isEdit values={gateway} onSubmit={handleUpdateGateway} />
        </div>
      </div>
    </div>
  );
}
export default EditGateway;